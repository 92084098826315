<template>
  <client-only>
    <ion-page v-bind="$attrs" :style="computedStyle">
      <slot></slot>
    </ion-page>
    <template #fallback>
      <div class="ion-page" v-bind="$attrs" :style="computedStyle">
        <slot></slot>
      </div>
    </template>
  </client-only>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  style: {
    type: Object,
    default: () => ({}),
  },
});

const computedStyle = computed(() => ({
  // Add any default styles here
  ...props.style,
}));
</script>
<style lang="sass" scoped>
.ion-page
  contain: unset !important
  min-height: 100%
  overflow: visible
  margin: auto
  position: relative !important
  max-width: 690px
</style>